import {
  AdapterMoment,
  Box,
  Button,
  DatePicker,
  enerbitColors,
  FilterListIcon,
  FormControl,
  KeyboardArrowDownIcon,
  LocalizationProvider,
  MenuItem,
  SearchIcon,
  Typography,
} from "@enerbit/base";
import React, { useState } from "react";
import StyledMenu from "../../../../components/StyledMenu/StyledMenu";
import CustomSelect from "../../../../components/Inputs/CustomSelect";
import { DATE_TIME_FORMAT, frontierTypeToName } from "../../../../common/frontiers";
import { Filters, FrontierStatus, FrontierType } from "../../../../models/frontiers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { setCurrentPage, setFilters } from "../../../../store/slices/frontiers";
import { listFrontiers } from "../../../../store/thunks/frontiers";

const Filter = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { filters } = useSelector((state: RootState) => state.frontiers);

  const dispatch = useDispatch<AppDispatch>();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (key: string, value: string) => {
    dispatch(setFilters({ key: key as keyof Filters, value }));
  };

  const onFilter = () => {
    handleCloseMenu();
    dispatch(setCurrentPage(1));
    dispatch(listFrontiers());
  };

  return (
    <>
      <Button
        startIcon={<FilterListIcon sx={{ color: enerbitColors.neutral.main }} />}
        endIcon={<KeyboardArrowDownIcon sx={{ color: enerbitColors.neutral[300] }} />}
        sx={{
          minHeight: "unset",
          borderRadius: "12px",
          border: "1px solid #A3A9B6",
          height: "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
        onClick={handleClick}>
        Filtros
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{ display: "flex", flexDirection: "column" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Box>
          <FormControl sx={{ mb: 1 }} fullWidth>
            <Typography>Tipo de frontera</Typography>
            <CustomSelect
              name='frontier_type'
              fullWidth
              value={filters.frontier_type}
              onChange={(e) => {
                handleInputChange("frontier_type", e.target.value as string);
              }}>
              {Object.keys(frontierTypeToName).map((key) => (
                <MenuItem key={key} value={key}>
                  {frontierTypeToName[key as FrontierType]}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          {/* <FormControl sx={{ mb: 1 }} fullWidth>
            <Typography>Estado</Typography>
            <CustomSelect name='frontier_status' fullWidth>
              {Object.keys(frontierStatuses).map((key) => (
                <MenuItem key={key} value={key}>
                  {frontierStatuses[key as FrontierStatus]}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl> */}
          <FormControl sx={{ mb: 1 }} fullWidth>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Typography>Fecha de registro XM</Typography>
              <DatePicker
                format='YYYY-MM-DD'
                value={moment(filters.xm_registered_date ?? "")}
                onChange={(value: any) => {
                  handleInputChange(
                    "xm_registered_date",
                    value ? value.startOf("day").format(DATE_TIME_FORMAT) : ""
                  );
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: false,
                    sx: {
                      "& fieldset": { borderRadius: "12px" },
                      "& .MuiInputBase-root": { height: "41px" },
                      ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "12px",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <Button
            startIcon={<SearchIcon />}
            onClick={onFilter}
            fullWidth
            variant='contained'
            color='secondary'
            sx={{ mt: 1 }}>
            Filtrar
          </Button>
        </Box>
      </StyledMenu>
    </>
  );
};

export default Filter;
