import { Box, styled } from "@enerbit/base";

const CustomContainer = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#FFF",
  padding: "24px 16px 24px 16px",
  borderRadius: "16px",
}));

export default CustomContainer;
