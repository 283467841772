import { DataGrid, styled } from "@enerbit/base";

const CustomDatagrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-columnHeaders": { backgroundColor: "#EEEBF4" },
  ".Mui-selected": { backgroundColor: "unset !important" },
  ".MuiDataGrid-cell:focus": { outline: "unset !important" },
  ".MuiDataGrid-cell": { borderBottom: "unset !important" },
  "& .MuiDataGrid-columnHeader,  .MuiDataGrid-cell,  .MuiDataGrid-cellCheckbox": {
    border: 0,
    outline: "none !important",
  },
  border: "none !important",
}));

export default CustomDatagrid;
