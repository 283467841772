import { Box, Button, CustomAlert, Skeleton, Typography } from "@enerbit/base";
import Backbutton from "../../components/BackButton/Backbutton";
import { useNavigate, useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import GeneralInformation from "./components/GeneralInformation/GeneralInformation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getFrontier } from "../../store/thunks/frontiers";
import DetailTabs from "./components/DetailTabs/DetailTabs";
import MeasuresChart from "./components/MeasuresChart/MeasuresChart";
import { setFrtDetail } from "../../store/slices/frontiers";

const FrontierDetail = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const { id } = useParams<{ id: string }>();
  const { frtDetail, loading, error } = useSelector((state: RootState) => state.frontiers);

  useEffect(() => {
    dispatch(setFrtDetail(null));
    if (!id) return;
    dispatch(getFrontier(id));
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Backbutton onClick={() => navigate("/")} />
          <Box>
            <Typography sx={{ color: "#53358E", fontWeight: 700, fontSize: "28px" }}>
              {id}
            </Typography>
            <Typography sx={{ color: "#667085" }}>Código SIC</Typography>
          </Box>
        </Box>
        <Button variant='contained' color='secondary' onClick={() => navigateToUrl("#/orders")}>
          Generar OS
        </Button>
      </Box>
      {error && (
        <CustomAlert
          text='Error consultando la información de esta frontera.'
          severity='error'
          onClose={() => {}}
        />
      )}
      <Box sx={{ mt: 2 }}>
        {loading && <Skeleton variant='rounded' height='197px' />}
        {!loading && <GeneralInformation />}
      </Box>
      {loading && <Skeleton variant='rounded' height='484px' sx={{ mt: 2 }} />}
      {frtDetail && !error && (
        <Box sx={{ mt: 2 }}>
          <MeasuresChart />
        </Box>
      )}
      {loading && <Skeleton variant='rounded' height='100px' sx={{ mt: 2 }} />}
      {frtDetail && !error && (
        <Box sx={{ mt: 2 }}>
          <DetailTabs />
        </Box>
      )}
    </Box>
  );
};

export default FrontierDetail;
