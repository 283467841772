import { enerbitColors } from "@enerbit/base";
import { OrderStatus } from "../models/orders";

export const ORDERS_HISTORY_TABLE_HEADERS: string[] = [
  "Tipo de acta",
  "Estado",
  "Fecha",
  // "Detalle",
];

export const ORDER_STATUS_NAMES: Record<
  OrderStatus,
  { label: string; bgcolor: string; fontcolor: string }
> = {
  cancel: {
    label: "Cancelada",
    bgcolor: enerbitColors.error[100] || "",
    fontcolor: enerbitColors.error.main,
  },
  done: {
    label: "Completada",
    bgcolor: enerbitColors.success[100] || "",
    fontcolor: enerbitColors.success.main,
  },
  pending: {
    label: "Completada",
    bgcolor: enerbitColors.warning[100] || "",
    fontcolor: enerbitColors.warning.main,
  },
};
