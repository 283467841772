import { createSlice } from "@reduxjs/toolkit";
import { GenerationFrontierState } from "../../models/frontiers";
import { getGenerationFrontierTypes } from "../thunks/generationFrontiers";

const initialState: GenerationFrontierState = {
  autogeneratorTypes: [],
  exportEnergyGenerationTypes: [],
  exportEnergyTypes: [],
  loading: false,
  error: false,
};

export const generationFrontier = createSlice({
  name: "generationFrontier",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getGenerationFrontierTypes.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getGenerationFrontierTypes.fulfilled, (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.autogeneratorTypes = payload.autogeneratorTypes;
      state.exportEnergyTypes = payload.exportEnergyTypes;
      state.exportEnergyGenerationTypes = payload.exportEnergyGenerationTypes;
    });
    builder.addCase(getGenerationFrontierTypes.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export const {} = generationFrontier.actions;
