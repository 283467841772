import { HashRouter, Route, Routes } from "react-router-dom";
import FrontiersList from "../pages/FrontiersList/FrontiersList";
import FrontierDetail from "../pages/FrontierDetail/FrontierDetail";

const Router = () => {
  return (
    <HashRouter basename='/frontiers'>
      <Routes>
        <Route path='/' element={<FrontiersList />} />
        <Route path='/:id' element={<FrontierDetail />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;
