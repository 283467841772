import { Box } from "@enerbit/base";
import { useState } from "react";
import { StyledTab } from "../../../../components/Tabs/StyledTab";
import { StyledTabs } from "../../../../components/Tabs/StyledTabs";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import MeasurementsTab from "../MeasurementsTab/MeasurementsTab";
import FrontiersTab from "../FrontierTab/FrontiersTab";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HomeTabs = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={value} onChange={handleChange}>
          {/* <StyledTab label={<Box>Medidas</Box>} {...a11yProps(0)} /> */}
          <StyledTab label={<Box>Fronteras</Box>} {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      {/* <TabPanel value={value} index={0}>
        <Box mt={3} pb={3}>
          <MeasurementsTab />
        </Box>
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <Box mt={3} pb={3}>
          <FrontiersTab />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default HomeTabs;
