import { Menu, MenuProps, styled } from "@enerbit/base";

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "16px",
    width: 300,
    padding: "24px",
    paddingBottom: "5px",
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.1) !important",
  },
}));

export default StyledMenu;

// box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);

// box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
