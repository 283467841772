import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetMeasuresParams, Usages } from "../../models/measurements";
import { formatUsagesChartData } from "../../utils/frontiers";

export const getMeasures = createAsyncThunk(
  "measurements/getMeasures",
  async ({ id, since, until }: GetMeasuresParams, { rejectWithValue }) => {
    const params = { since: encodeURI(since), until: encodeURI(until) };
    try {
      const res = await api.get<Usages[]>(
        `/electricity-supply-service/device/usages/measurement-points/${id}/`,
        { params }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
