import {
  Box,
  Button,
  CustomAlert,
  DownloadIcon,
  IconButton,
  InputAdornment,
  LoadingButton,
  SearchIcon,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomContainer from "../../../../components/Containers/CustomContainer";
import CustomTextField from "../../../../components/Inputs/CustomTextField";
import { useEffect, useState } from "react";
import FrontierTable from "../FrontierTable/FrontierTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import TableSkeleton from "../../../../components/Table/TableSkeleton";
import { listFrontiers } from "../../../../store/thunks/frontiers";
import {
  resetFilters,
  setCurrentPage,
  setFrtDetail,
  setFrtToDownload,
  setSearchValue,
  setSelectedFrts,
} from "../../../../store/slices/frontiers";
import Filter from "../Filters/Filters";
import ReplayIcon from "@mui/icons-material/Replay";
import { getGenerationFrontierTypes } from "../../../../store/thunks/generationFrontiers";
import GenerationFrontierModal from "../GenerationFrontierModal/GenerationFrontierModal";
import { downloadFrtsFile } from "../../../../utils/frontiers";
import { Frontier } from "../../../../models/frontiers";

const FrontiersTab = () => {
  const [createGeneratingFrt, setCreateGeneratingFrt] = useState<boolean>(false);
  const { loading: loadingGenrationTypes } = useSelector(
    (state: RootState) => state.generationFrontiers
  );
  const { loading, error, searchValue, selectedFrts, frontiers, toDownload } = useSelector(
    (state: RootState) => state.frontiers
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!searchValue) return;
    dispatch(setFrtDetail(null));
    dispatch(setCurrentPage(1));
    dispatch(listFrontiers());
  };

  const handleReset = () => {
    dispatch(setFrtDetail(null));
    dispatch(setFrtToDownload([]));
    dispatch(setCurrentPage(1));
    dispatch(setSelectedFrts([]));
    dispatch(setSearchValue(""));
    dispatch(resetFilters());
    dispatch(listFrontiers());
  };

  const handleDownload = () => {
    if (selectedFrts.length === 0) return;
    const tempFrts: Frontier[] = [];
    selectedFrts.forEach((id) => {
      const frt = toDownload.find((item) => item.frontier_xm_code === id);
      if (frt) tempFrts.push(frt);
    });
    downloadFrtsFile(tempFrts);
  };

  useEffect(() => {
    dispatch(getGenerationFrontierTypes());
    handleReset();
  }, []);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ color: enerbitColors.neutral[700], fontSize: "20px", fontWeight: 700 }}>
            Lista de fronteras
          </Typography>
          <LoadingButton
            variant='contained'
            color='secondary'
            loading={loadingGenrationTypes}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setCreateGeneratingFrt(true)}>
            Agregar frontera generadora
          </LoadingButton>
        </Box>
        <CustomContainer mt={2}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <form onSubmit={handleSearch} style={{ display: "flex" }}>
              <CustomTextField
                placeholder='Buscar'
                value={searchValue}
                onChange={(e) => dispatch(setSearchValue(e.target.value))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant='contained'
                color='primary'
                type='submit'
                sx={{
                  minHeight: "unset !important",
                  height: "38px",
                  minWidth: "unset !important",
                  width: "41px",
                  ml: 1,
                  mr: 2,
                }}>
                <SearchIcon />
              </Button>
              <IconButton onClick={handleReset}>
                <ReplayIcon />
              </IconButton>
            </form>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Filter />
              <Button
                variant='contained'
                color='primary'
                startIcon={<DownloadIcon />}
                onClick={handleDownload}>
                Descargar
              </Button>
            </Box>
          </Box>
          <Box mt={2}>
            {loading && <TableSkeleton rows={6} columns={10} />}
            {!loading && !error && frontiers.length > 0 && <FrontierTable />}
            {!loading && !error && frontiers.length === 0 && (
              <CustomAlert text='No se encontraron fronteras.' severity='info' onClose={() => {}} />
            )}
            {error && (
              <CustomAlert
                text='Error listando las fronteras.'
                severity='error'
                onClose={() => {}}
              />
            )}
          </Box>
        </CustomContainer>
      </Box>
      <GenerationFrontierModal
        open={createGeneratingFrt}
        handleClose={() => setCreateGeneratingFrt(false)}
      />
    </>
  );
};

export default FrontiersTab;
