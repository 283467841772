import { styled, Tabs } from "@enerbit/base";

interface StyledTabsProps {
  className?: string | undefined;
  value: number;
  onChange: (_event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => {
  return (
    <Tabs
      {...props}
      value={props.value}
      onChange={props.onChange}
      classes={{
        root: props.className,
        flexContainer: "flexContainer",
        indicator: "indicator",
      }}
      TabIndicatorProps={{ children: <span /> }}
    />
  );
})({
  "& .indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 50,
      width: "100%",
      backgroundColor: "#FF7705",
    },
  },
  "& .flexContainer": {
    // width:'fit-content'
  },
  "&.MuiButtonBase .MuiTab-root": {
    padding: "0px",
  },
});
