import { initEnviroment, PrivateRoute, ThemeConfig } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { store } from "./store/store";
import MainLayout from "./layouts/MainLayout";
import Router from "./router/Router";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
  // firebaseVapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
  // firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  // firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_ID,
});

export default hot(function Root() {
  return (
    <PrivateRoute>
      <Provider store={store}>
        <ThemeConfig>
          <MainLayout>
            <Router />
          </MainLayout>
        </ThemeConfig>
      </Provider>
    </PrivateRoute>
  );
});
