import * as yup from "yup";

export const generatingFrontierFormValidation = yup.object().shape({
  export_energy_power_capacity: yup.number().min(0).required("Este campo es requerido"),
  import_frontier_xm_code: yup.string().required("Este campo es requerido"),
  frontier_xm_autogenerator_code: yup
    .string()
    .required("Este campo es requerido")
    .trim()
    .matches(/^Frt\d{5}$/, "El código de la frontera debe tener el formato FrtXXXXX"),
  export_energy_start_datetime: yup.string().required("Este campo es requerido"),
  back_up_agreement: yup.boolean().required("Este campo es requerido"),
  back_up_agreement_power_capacity: yup.number().min(0),
  autogenerator_type_code: yup.string().required("Este campo es requerido"),
  export_energy_type_code: yup.string().required("Este campo es requerido"),
  export_energy_generation_type_code: yup.string().required("Este campo es requerido"),
});
