import { Button } from "@enerbit/base";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props {
  onClick: () => void;
}

const Backbutton = ({ onClick }: Props) => {
  return (
    <Button
      variant='outlined'
      color='primary'
      onClick={onClick}
      sx={{
        borderRadius: "50% !important",
        height: "41px !important",
        width: "41px !important",
        minWidth: "unset !important",
      }}>
      <ArrowBackIcon />
    </Button>
  );
};

export default Backbutton;
