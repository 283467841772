import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GenerateFrontierTypes } from "../../models/frontiers";

export const getGenerationFrontierTypes = createAsyncThunk(
  "generationFrontiers/getGenerationFrontierTypes",
  async (_, { rejectWithValue }) => {
    try {
      const autogeneratorTypes = await api.get<GenerateFrontierTypes[]>(
        "/frontiers/agpe-frontiers/autogenerator-types/"
      );
      const exportEnergyTypes = await api.get<GenerateFrontierTypes[]>(
        "/frontiers/agpe-frontiers/export-energy-types/"
      );
      const exportEnergyGenerationTypes = await api.get<GenerateFrontierTypes[]>(
        "/frontiers/agpe-frontiers/export-energy-generation-types/"
      );

      return {
        autogeneratorTypes: autogeneratorTypes.data,
        exportEnergyTypes: exportEnergyTypes.data,
        exportEnergyGenerationTypes: exportEnergyGenerationTypes.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
