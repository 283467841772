import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FrontiersResponse } from "../../models/frontiers";
import { RootState } from "../store";
import { DATE_TIME_FORMAT, frontierTypeToName } from "../../common/frontiers";
import moment from "moment";

const PAGES_SIZE = 50;

export const listFrontiers = createAsyncThunk(
  "frontiers/listFrontiers",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;

    const { currentPage, searchValue, filters } = state.frontiers;

    const params: Record<string, any> = {};

    if (searchValue) params["frontier_xm_code"] = searchValue;
    if (filters.frontier_type) params["frontier_type"] = filters.frontier_type;

    if (filters.xm_registered_date) {
      params["since_xm_registered"] = moment(filters.xm_registered_date)
        .startOf("day")
        .format(DATE_TIME_FORMAT);
      params["until_xm_registered"] = moment(filters.xm_registered_date)
        .endOf("day")
        .format(DATE_TIME_FORMAT);
    }

    try {
      const res = await api.get<FrontiersResponse>(
        `/frontiers/frontiers/?page=${currentPage}&size=${PAGES_SIZE}`,
        { params }
      );

      const { items, page, pages } = res.data;

      return { data: items, currentPage: page, totalPages: pages };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFrontier = createAsyncThunk(
  "frontiers/getFrontier",
  async (code: string, { rejectWithValue, getState }) => {
    const params: Record<string, any> = { frontier_xm_code: code };
    try {
      const res = await api.get<FrontiersResponse>(`/frontiers/frontiers/?page=0&size=50`, {
        params,
      });

      const { items } = res.data;

      const data: { label: string; value: string | number }[] = [
        { label: "Nombre", value: items[0].DisplayName },
        { label: "Tipo frontera", value: frontierTypeToName[items[0].frontier_type] },
        { label: "Dirección", value: items[0].Street },
        {
          label: "Fecha de registro ante XM",
          value: moment(items[0].frontier_xm_registered_from).utcOffset(0).format("YYYY-MM-DD"),
        },
        { label: "Código lead en Odoo", value: items[0].LeadIntegratorID },
        { label: "Ciudad, departamento", value: `${items[0].City}, ${items[0].Department}` },
        { label: "# Serie medidor principal", value: items[0].MeterNumber },
        { label: "Operador de red", value: items[0].CurrentNetworkOperator },
      ];

      return { data, frt: items[0] };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
