import { Box, styled } from "@enerbit/base";

interface Props {
  bgcolor: string;
  fontcolor: string;
}

const StatusTag = styled(Box)<Props>(({ bgcolor, fontcolor }) => ({
  borderRadius: "8px",
  padding: "0px 16px 0px 16px",
  backgroundColor: bgcolor,
  color: fontcolor,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "32px",
}));

export default StatusTag;
