import { api } from "@enerbit/base";
import { CreateGeneratingFrontier } from "../models/frontiers";
import { DATE_TIME_FORMAT } from "../common/frontiers";
import moment from "moment";

export const createGenerationFrontier = async (data: CreateGeneratingFrontier) => {
  await api.post("/frontiers/agpe-frontiers/agpe-frontier/", undefined, {
    params: {
      ...data,
      export_energy_start_datetime: moment(data.export_energy_start_datetime).format(
        DATE_TIME_FORMAT
      ),
      back_up_agreement_power_capacity: data.back_up_agreement
        ? data.back_up_agreement_power_capacity
        : 0,
    },
  });
};
