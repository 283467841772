import { enerbitColors } from "@enerbit/base";
import { FrontierStatus, FrontierType, UsagesChartDataModel } from "../models/frontiers";

export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZZ";

export const frontierTypeToName: Record<FrontierType, string> = {
  export_frontier: "Exportadora",
  import_frontier: "Importadora",
};

export const frontierStatusesColor: Record<
  string,
  { fontcolor: string; bgcolor: string; label: string }
> = {
  new: {
    bgcolor: enerbitColors.success[100] || "",
    fontcolor: enerbitColors.success.main || "",
    label: "Nueva",
  },
  old: {
    bgcolor: "#EEEBF4",
    fontcolor: "#53358E",
    label: "Antigua",
  },
  incoming: {
    bgcolor: enerbitColors.warning[100] || "",
    fontcolor: enerbitColors.warning.main || "",
    label: "En ingreso",
  },
};

export const frontierStatuses: Record<FrontierStatus, string> = {
  old: "Antigua",
  new: "Nueva",
  incoming: "En ingreso",
};

export const CSV_FILE_HEADERS: string[] = [
  "Código",
  "Nombre",
  "Tipo de frontera",
  "Ubicación",
  "Dirección",
  "Fecha de registro ante XM",
  "Serial medidor principal",
  "Operador de red",
  "Código lead en Odoo",
];

export const quadrantsToName: Record<string, string> = {
  active_energy_imported: "Energía activa importada",
  reactive_energy_imported: "Energía reactiva importada",
  active_energy_exported: "Energía activa exportada",
  reactive_energy_exported: "Energía reactiva exportada",
};

export const quadrantsColors: Record<string, string> = {
  active_energy_imported: "hsl(217, 91%, 60%)",
  reactive_energy_imported: "hsl(152, 82%, 39%)",
  active_energy_exported: "hsl(27, 100%, 51%)",
  reactive_energy_exported: "hsl(260, 46%, 38%)",
};

export const USAGES_CHART_DATASETS: UsagesChartDataModel[] = [
  {
    label: "Ener. Activa importada",
    data: "active_energy_imported",
    borderColor: "rgba(59, 130, 246, 1)",
    backgroundColor: "rgba(59, 130, 246, 1)",
  },
  {
    label: "Ener. Reactiva importada",
    data: "reactive_energy_imported",
    borderColor: "rgba(18, 183, 106, 1)",
    backgroundColor: "rgba(18, 183, 106, 1)",
  },
  {
    label: "Ener. Activa exportada",
    data: "active_energy_exported",
    borderColor: "rgba(255, 119, 5, 1)",
    backgroundColor: "rgba(255, 119, 5, 1)",
  },
  {
    label: "Ener. Reactiva exportada",
    data: "reactive_energy_exported",
    borderColor: "rgba(83, 53, 142, 1)",
    backgroundColor: "rgba(83, 53, 142, 1)",
  },
];
