import { Box, Typography, enerbitColors } from "@enerbit/base";
import HomeTabs from "./components/HomeTabs/HomeTabs";

const FrontiersList = () => {
  return (
    <Box>
      <Typography sx={{ color: enerbitColors.primary.main, fontSize: "28px", fontWeight: 700 }}>
        Zenit
      </Typography>
      <Box sx={{ my: 3 }}>
        <HomeTabs />
      </Box>
    </Box>
  );
};

export default FrontiersList;
