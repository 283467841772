import PaginationContainer from "../../../../components/Containers/PaginationContainer";
import {
  CustomAlert,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import moment from "moment";
import StatusTag from "../../../../components/Containers/StatusTag";
import { StyledTableHeader } from "../../../../components/Table/StyledTable";
import { ORDERS_HISTORY_TABLE_HEADERS, ORDER_STATUS_NAMES } from "../../../../common/orders";
import { setCurrentPage } from "../../../../store/slices/orders";
import { getOrders } from "../../../../store/thunks/orders";

const OrdersHistory = () => {
  const { orders, currentPage, totalPages, error, loading } = useSelector(
    (state: RootState) => state.ordersHistory
  );

  const { frtDetail } = useSelector((state: RootState) => state.frontiers);

  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (!frtDetail) return;

    event.preventDefault();
    dispatch(setCurrentPage(value - 1));
    dispatch(getOrders(frtDetail.measurement_point_id || frtDetail.lead_id || ""));
  };

  return (
    <>
      {error && (
        <CustomAlert
          text='Error cargando historial de órdenes de servicio'
          severity='error'
          onClose={() => {}}
        />
      )}
      {!error && !loading && orders.length === 0 && (
        <CustomAlert
          text='No se encontraron órdenes de servicio para esta frontera.'
          severity='info'
          onClose={() => {}}
        />
      )}
      {!error && !loading && orders.length > 0 && (
        <>
          <TableContainer component={Paper} sx={{ boxShadow: "unset !important" }}>
            <Table>
              <TableHead>
                <StyledTableHeader>
                  {ORDERS_HISTORY_TABLE_HEADERS.map((item, index) => (
                    <TableCell key={`${index + 1}-${item}`}>{item}</TableCell>
                  ))}
                </StyledTableHeader>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow
                    key={order.id}
                    sx={{
                      "& .MuiTableCell-root": {
                        borderBottom: "unset !important",
                        padding: "7px !important",
                      },
                    }}>
                    <TableCell>{order.name}</TableCell>
                    <TableCell>
                      <StatusTag
                        fontcolor={ORDER_STATUS_NAMES[order.status].fontcolor}
                        bgcolor={ORDER_STATUS_NAMES[order.status].bgcolor}>
                        {ORDER_STATUS_NAMES[order.status].label}
                      </StatusTag>
                    </TableCell>
                    <TableCell>
                      {moment(order.date).utcOffset("-05:00").format("YYYY-MM-DD")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationContainer sx={{ mt: 1 }}>
            <Stack spacing={2}>
              <Pagination
                page={currentPage + 1}
                count={totalPages}
                shape='rounded'
                onChange={handleChange}
              />
            </Stack>
          </PaginationContainer>
        </>
      )}
    </>
  );
};

export default OrdersHistory;
